import { Component, OnInit, Output, EventEmitter, AfterViewInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ModalStartComponent } from 'src/app/modal-start/modal-start.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { environment } from 'src/environments/environment';
import { SupportComponent } from '../modals/support/support.component';
import { ModalOpenApplicationComponent } from 'src/app/modal-open-application/modal-open-application.component';
import {getHeaderSelectorsState} from './store/header.selectors'
import {setHeaderAction} from './store/header.actions'
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LoadingService } from 'src/app/shared/loading.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  @Input() isHomePage: boolean = true;
  check$: Observable<any>;
  storeData: any
  application: String = '';
  constructor(
    public authService: OidcSecurityService,
    public router: Router,
    public dialog: MatDialog,
    public ngxPermissionService: NgxPermissionsService,
    private store: Store,
    private loadingService: LoadingService,
  ) {}


  ngOnInit() {
    this.getStates()
  }

  getStates() {
    this.application = this.loadingService.getLoadingState();
    this.check$ = this.store.select(getHeaderSelectorsState);
    this.check$.subscribe((value) => {
      this.storeData = {
        ...(this.storeData = value)
      }
    });
  }

  setStates(check: boolean) {
    this.store.dispatch(setHeaderAction({ check }));
  }

  openSupportModal() {
    this.dialog.open(SupportComponent);
  }

  openApplication(application: 'apolo' | 'selectus' | 'oraculo' | 'dashplan') {
    const params = {
      application,
    };

    const permissions = this.ngxPermissionService.getPermissions();
    if (permissions['members::list::my'] == undefined) {
      location.href = environment.clientRedirect
        .replace('techfinance', application + '.techfinance');
      return;
    }
    this.dialog.open(ModalOpenApplicationComponent, {
      data: {
        application: params.application,
        panelClass: 'custom-dialog-container',
      },
    });
  }

  loginRoute(route) {
    location.href =
      environment.clientRedirect
        .replace('techfinance', 'login.techfinance')
        .replace('qa', 'qa')
        .replace('login.tf', 'login') + route;
  }
  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }
  logout() {
    this.authService.logoff();
  }
}
