<div class="page-container">
  <header class="bg-default-white d-flex shadow border-bottom">
    <app-header
      style="background-color: #edeef0"
      class="w-100 d-flex justify-content-center border"
    ></app-header>
  </header>
  <!-- <div class="alert alert-primary py-1" *ngIf="showDisplayAlertNotification" role="alert">
    <div class="align-items-center">
      <div class="d-flex justify-content-between">
        <div></div>
        <div class="d-flex align-items-center">
          <div
            class=" p-2 font-weight-bold mr-2"
          >
          <div class="">
            <span
              style=" font-size: 14px; border: 1px solid #0056D033;"
              class="badge d-flex gap-1 align-items-center p-1 px-2 text-black"
            >
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 1.5C7.21442 1.5 5.95772 1.88122 4.8888 2.59545C3.81988 3.30968 2.98676 4.32484 2.49479 5.51256C2.00282 6.70028 1.87409 8.00721 2.1249 9.26809C2.3757 10.529 2.99477 11.6872 3.90381 12.5962C4.81285 13.5052 5.97104 14.1243 7.23192 14.3751C8.49279 14.6259 9.79973 14.4972 10.9874 14.0052C12.1752 13.5132 13.1903 12.6801 13.9046 11.6112C14.6188 10.5423 15 9.28558 15 8C14.9982 6.27665 14.3128 4.62441 13.0942 3.40582C11.8756 2.18722 10.2234 1.50182 8.5 1.5ZM13.9769 7.5H11.4875C11.3994 5.72125 10.8456 4.03312 9.9175 2.6875C11.0045 2.97933 11.9761 3.5969 12.7018 4.45722C13.4275 5.31753 13.8725 6.37936 13.9769 7.5ZM8.5 13.4931C7.32938 12.2262 6.61938 10.4306 6.51438 8.5H10.4856C10.3806 10.4294 9.67063 12.2262 8.5 13.4931ZM6.51438 7.5C6.61938 5.57062 7.32688 3.77375 8.5 2.50688C9.67063 3.77375 10.3806 5.56938 10.4856 7.5H6.51438ZM7.0825 2.6875C6.15438 4.03312 5.60063 5.72125 5.5125 7.5H3.02313C3.12756 6.37936 3.57251 5.31753 4.29819 4.45722C5.02387 3.5969 5.9955 2.97933 7.0825 2.6875ZM3.02313 8.5H5.5125C5.60063 10.2787 6.15438 11.9669 7.0825 13.3125C5.9955 13.0207 5.02387 12.4031 4.29819 11.5428C3.57251 10.6825 3.12756 9.62064 3.02313 8.5ZM9.9175 13.3125C10.8456 11.965 11.3994 10.2769 11.4875 8.5H13.9769C13.8725 9.62064 13.4275 10.6825 12.7018 11.5428C11.9761 12.4031 11.0045 13.0207 9.9175 13.3125Z"
                  fill="#001847"
                />
              </svg>
              Ecossistema
            </span>
          </div>
          </div>
          <span>
            Conheça o CRM projetado para Wealth Planners que agora está no Ecossistema TechFinance!
          </span>
          <a href="https://pg.empreenderdinheiro.com.br/techfinance/crm-planos-digital/"
            class="btn mx-1 font-weight-bold "
            target="_blank"
            ><span>Saber como aderir </span>
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.3538 8.35378L9.85375 12.8538C9.75993 12.9476 9.63268 13.0003 9.5 13.0003C9.36732 13.0003 9.24007 12.9476 9.14625 12.8538C9.05243 12.76 8.99972 12.6327 8.99972 12.5C8.99972 12.3674 9.05243 12.2401 9.14625 12.1463L12.7931 8.50003H3C2.86739 8.50003 2.74021 8.44736 2.64645 8.35359C2.55268 8.25982 2.5 8.13264 2.5 8.00003C2.5 7.86743 2.55268 7.74025 2.64645 7.64648C2.74021 7.55271 2.86739 7.50003 3 7.50003H12.7931L9.14625 3.85378C9.05243 3.75996 8.99972 3.63272 8.99972 3.50003C8.99972 3.36735 9.05243 3.2401 9.14625 3.14628C9.24007 3.05246 9.36732 2.99976 9.5 2.99976C9.63268 2.99976 9.75993 3.05246 9.85375 3.14628L14.3538 7.64628C14.4002 7.69272 14.4371 7.74786 14.4623 7.80856C14.4874 7.86926 14.5004 7.93433 14.5004 8.00003C14.5004 8.06574 14.4874 8.13081 14.4623 8.1915C14.4371 8.2522 14.4002 8.30735 14.3538 8.35378Z"
                fill="#001E2E"
              />
            </svg>
          </a>
        </div>
        <div>
          <button type="button" (click)="closeDisplayNotification('alert')" class="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
  </div> -->

  <section class="flex-col bg-figma-white">
    <div class="welcome h2 content-container p-4 mt-4">
      <p class="ml-3">Boas-vindas, {{ userName }}.</p>
    </div>
    <!-- <div
      *ngIf="!storeData.check && showDisplayBannerNotification"
      class="pb-2 content-container"
    >
      <div class="d-flex gap-1 align-content-start align-items-start px-4">
        <app-head-card> </app-head-card>
        <div>
          <svg
            width="22"
            height="22"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mt-4 close-button"
            (click)="closeDisplayNotification('banner')"
          >
            <path
              d="M15.281 14.2193C15.3507 14.289 15.406 14.3717 15.4437 14.4628C15.4814 14.5538 15.5008 14.6514 15.5008 14.7499C15.5008 14.8485 15.4814 14.9461 15.4437 15.0371C15.406 15.1281 15.3507 15.2109 15.281 15.2806C15.2114 15.3502 15.1286 15.4055 15.0376 15.4432C14.9465 15.4809 14.849 15.5003 14.7504 15.5003C14.6519 15.5003 14.5543 15.4809 14.4632 15.4432C14.3722 15.4055 14.2895 15.3502 14.2198 15.2806L8.00042 9.06024L1.78104 15.2806C1.64031 15.4213 1.44944 15.5003 1.25042 15.5003C1.05139 15.5003 0.860523 15.4213 0.719792 15.2806C0.579062 15.1398 0.5 14.949 0.5 14.7499C0.5 14.5509 0.579062 14.36 0.719792 14.2193L6.9401 7.99993L0.719792 1.78055C0.579062 1.63982 0.5 1.44895 0.5 1.24993C0.5 1.05091 0.579062 0.860034 0.719792 0.719304C0.860523 0.578573 1.05139 0.499512 1.25042 0.499512C1.44944 0.499512 1.64031 0.578573 1.78104 0.719304L8.00042 6.93962L14.2198 0.719304C14.3605 0.578573 14.5514 0.499512 14.7504 0.499512C14.9494 0.499512 15.1403 0.578573 15.281 0.719304C15.4218 0.860034 15.5008 1.05091 15.5008 1.24993C15.5008 1.44895 15.4218 1.63982 15.281 1.78055L9.06073 7.99993L15.281 14.2193Z"
              fill="#191C1E"
            />
          </svg>
        </div>
      </div>
    </div> -->
    <app-menu
      class="bg-figma-white content-container"
      (page)="update($event)"
    ></app-menu>
    <div class="content-container pl-5 pr-5">
      <app-softwares
        *ngIf="currentPage == 'softwares'"
        class="ml-1 content-container"
      ></app-softwares>
      <app-education
        *ngIf="currentPage == 'education'"
        class="content-container"
      ></app-education>
      <app-ecosystem
        *ngIf="currentPage == 'ecosystem'"
        class="ml-1 content-container"
      ></app-ecosystem>
      <app-material
        *ngIf="currentPage == 'material'"
        class="content-container"
      ></app-material>
      <app-all-icons class="content-container" *ngIf="currentPage == 'home'">
      </app-all-icons>
    </div>
  </section>
  <div class="p-3 pb-5" *ngIf="!storeData.check">
    <div class="d-flex justify-content-center bg-figma-white">
      <a
        [href]="
          'https://pg.empreenderdinheiro.com.br/pc/demonstracao-techfinance-business/'
        "
        target="_blank"
        class="text-decoration-none link-blur"
      >
        <div>
          <img
          [src]="'/assets/images/homeTF/BANNER-TFB-PARA-A-TFv2.jpg'"
            alt="Banner TechFinance"
            class="img-fluid rounded"
            style="width: 1360px"
          />
        </div>
      </a>
    </div>
    <!-- <app-slider></app-slider> -->
  </div>

  <footer class="footer py-1 bg-default-white border-top mt-auto">
    <app-footer class="content-container"></app-footer>
  </footer>
</div>