import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxPermissionsService } from 'ngx-permissions';
import { ModalOpenApplicationComponent } from 'src/app/modal-open-application/modal-open-application.component';
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { SoftwareModalComponent } from '../modals/software-modal/software-modal.component';

@Component({
  selector: 'app-softwares',
  templateUrl: './softwares.component.html',
  styleUrls: ['./softwares.component.scss'],
})
export class SoftwaresComponent implements OnInit {
  @Input() haveLateralName: boolean = false;

  baseUrl: string = environment.baseUrl;
  accessToken: string | null ;

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    public ngxPermissionService: NgxPermissionsService
  ) {}

  ngOnInit(): void {

    this.accessToken = localStorage.getItem('access_token');
  }

  openModal(application: 'dashplan') {
    const params = {
      application,
    };

    this.dialog.open(SoftwareModalComponent, {
      data: params,
    });
  }

  openApplication(application: 'apolo' | 'selectus' | 'oraculo' | 'dashplan') {
    const params = {
      application,
    };

    const permissions = this.ngxPermissionService.getPermissions();
    if (permissions['members::list::my'] == undefined) {
      location.href = environment.clientRedirect
        .replace('techfinance', application + '.techfinance')
        .replace('tf.qa', 'qatf');
      return;
    }

    this.dialog.open(ModalOpenApplicationComponent, {
      data: {
        application: params.application,
        panelClass: 'custom-dialog-container',
      },
    });
  }
}
