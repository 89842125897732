import { Component, OnInit } from '@angular/core';
import { UsersApplicationsService } from '../../modal-open-application/users-applications.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { v4 } from 'uuid';
import { LoadingService } from 'src/app/shared/loading.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-signup-a-client',
  templateUrl: './signup-a-client.component.html',
  styleUrls: ['./signup-a-client.component.scss'],
})
export class SignupAClientComponent implements OnInit {
  fullName: string = '';
  email: string = '';
  phone: string = '';
  errorMessage: string = '';
  isFullNameValid: boolean = true;

  constructor(
    private loadingService: LoadingService,
    private toste: ToastrService,
    private userService: UsersApplicationsService,
    private dialog: MatDialog,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.dialog.closeAll();
  }

  validateFullName(): void {
    this.isFullNameValid = this.fullName.trim().includes(' ');
  }

  formatPhoneNumber(): void {
    const cleaned = this.phone.replace(/\D/g, '');
    this.phone = cleaned
      .replace(/^(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2');
  }
  onlyAllowNumbers(event: KeyboardEvent): void {
    const charCode = event.key.charCodeAt(0);
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
  
  create(clientId: string) {
    this.openLink(v4(), clientId);
  }
  openLink(id: string, clientId: string) {
    const aplicationselector = this.loadingService.getLoadingState();

    this.userService.getAccessToken(clientId, aplicationselector, id)
    .subscribe((response: any) => {
      this.openFolder( response.accessToken);
    },() => {
      alert('Tivemos um problema ao abrir a pasta deste usuário.')
    })
  }
  openFolder(token: string) {
    const aplicationselector = this.loadingService.getLoadingState();

    location.href = environment.clientRedirect.replace('techfinance', aplicationselector + '.techfinance').replace('.qa.', '.') + '/folder-token/callback/' + token;
  }

  onSubmit() {
    if (!this.isFullNameValid || !this.email || !this.phone) {
      this.errorMessage = 'Por favor, preencha todos os campos corretamente.';
      return;
    }

    const userData = {
      fullName: this.fullName,
      email: this.email,
      phone: this.phone.replace(/\D/g, ''),
      origin: 'pasta:userid',
    };

    this.userService.createUserFromZero(userData).subscribe(
      (response) => {
        this.toste.success(`Cliente "${userData.fullName}" foi cadastrado`);
        const userId = response._id
        this.create(userId)
      },
      (error) => {
        this.errorMessage = error.error.message || 'Ocorreu um erro no cadastro.';
      }
    );
  }
}
