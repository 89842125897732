import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UsersApplicationsService } from './users-applications.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/shared/loading.service';

@Component({
  selector: 'app-modal-open-application',
  templateUrl: './modal-open-application.component.html',
  styleUrls: ['./modal-open-application.component.scss']
})
export class ModalOpenApplicationComponent implements OnInit {
  isDashplanVideo: Boolean = false;
  simpleRouteEnum: 'home' | 'select-client' | 'my-studies' = 'home';
  application: string;
  myId: string;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: {application: string},
    public usersApplications: UsersApplicationsService,
    private loadingService: LoadingService,
  ) {
    this.application = data.application;
    
    this.loadingService.setLoadingState(this.application); 
  }

  ngOnInit(): void {
    this.usersApplications.getUser().subscribe((data: any)=> this.myId = data._id);
  }
  openLink(subdomain: string, route: string) {
    if(subdomain==='login' && environment.isQa === true) {
      location.href = ('https://' + subdomain + '.' + environment.baseUrl + route).replace('login.tf', 'login');
      return;
    } 
    location.href = (environment.clientRedirect.replace('techfinance', subdomain + '.techfinance') + route).replace('.qa.', '.qa.').replace('login.tf', 'login.qa');
  }
  modalClose():void{
    this.dialog.closeAll();

  }
  routeChanger(endpoint: string) {
    if (endpoint === 'select-a-client') {
      this.dialog.closeAll();

      this.router.navigate(['/estudos/usuarios']);

    }
    if (endpoint === 'personal-study') {
      this.dialog.closeAll();

      this.router.navigate(['/estudos/estudos-pessoais']);

    }
  }
  removeTfSuffix(value: string): string {
    return value.endsWith('.tf') ? value.slice(0, -3) : value;
  }
}
