import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { CadastroComponent } from './modules/cadastro/cadastro.component';
import { LoginComponent } from './modules/login/login.component';
import { RecoveryPasswordComponent } from './modules/recovery-password/recovery-password.component';
import { AuthGuard } from './shared/auth-guard.service';
import { HomeComponent } from './modules/home/home.component';
import { CallbackComponent } from './callback/callback.component';
import { FaleConoscoComponent } from './modules/fale-conosco/fale-conosco.component';
import { HomepageComponent } from './modules/homepage/homepage.component';
import { PrecisoComponent } from './preciso/preciso.component';
import { SelectAClientComponent} from './studies-clientes-screens/select-a-client/select-a-client.component';
import { SignupAClientComponent} from './studies-clientes-screens/signup-a-client/signup-a-client.component';
import { DocumentosOficiaisComponent} from './documentos-oficiais/documentos-oficiais/documentos-oficiais.component';
const routes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    canActivate: [AuthGuard],
    children: [
      {
        redirectTo: '/inicio',
        path: '',
        pathMatch: 'full',
      },
      {
        path: 'inicio',
        component: HomepageComponent,
      },
      {
        path: 'login',
        component: HomepageComponent,
      },
      {
        path: 'fale-conosco',
        component: FaleConoscoComponent,
      },
      {
        path: 'preciso',
        component: PrecisoComponent,
      },
      {
        path: 'estudos/:personalstudies',
        component: SelectAClientComponent,
      },
      {
        path: 'estudos/usuarios/create',
        component: SignupAClientComponent,
      },
      {
        path :'documentos-oficiais',
        component: DocumentosOficiaisComponent,
      },
    ],
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'cadastro/alunoempreenderdinheiro',
    component: CadastroComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
