
<section>
  <div class="d-flex">
    <div *ngIf="haveLateralName">
      <p style="font-size: 16px; width: 100px;" class="fw-medium">Softwares</p>
    </div>
    <div class="card-menu m-2 p-2 px-3 rounded">
      <app-card-link
        class="softwares-card"
        style="width: 100%"
        data-bs-toggle="tooltip"
        [title]="'Guia'"
        title="Consultoria Express"
        [imgPath]="'/assets/images/homeTF/logo-guia.svg'"
        [link]="'https://guia.techfinance.com.br/'"
      ></app-card-link>
    </div>
    <div class="card-menu m-2 p-2 px-3 rounded">
      <app-card-nolink
      style="width: 100%"
      data-bs-toggle="tooltip"
        class="softwares-card"
        [title]="'Apolo'"
        title="Planejamento Patrimonial"
        [imgPath]="'/assets/images/homeTF/logo-apolo.svg'"
        (click)="openApplication('apolo')"
      ></app-card-nolink>
    </div>
    <div class="card-menu m-2 p-2 px-3 rounded">
      <app-card-nolink
      style="width: 100%"
      data-bs-toggle="tooltip"
        class="softwares-card"
        [title]="'Oráculo'"
        title="Asset Allocation"
        [imgPath]="'/assets/images/homeTF/logo-oraculo.svg'"
        (click)="openApplication('oraculo')"
      ></app-card-nolink>
    </div>
    <div class="card-menu m-2 p-2 px-3 rounded">
      <app-card-nolink
      style="width: 100%"
      data-bs-toggle="tooltip"
        class="softwares-card"
        [title]="'Dashplan'"
        title="Planejamento Financeiro"
        [imgPath]="'/assets/images/homeTF/logo-dashplan.svg'"
        (click)="openApplication('dashplan')"
      ></app-card-nolink>
    </div>
  </div>
</section>
